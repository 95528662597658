<div class="mr-pages container-fluid ">
  <div class="row">

    <div class="col-12 alert alert-success" role="alert">
      {{textAlert}}
    </div>
    <div class="col-12 background-white mt-4 maxhi">
    <app-table [showPaginator]="false" [columns]="header" [length]="totalRegistros" [dataSource]="body" (AccionsInput)="valueData($event)">

      </app-table>
    </div>
    <div class="col-12 d-flex justify-content-between background-white mt-4">
      <button (click)="close()" class="btn btn-secondary">
        Cancelar
      </button>
      <button (click)="close()" class="btn btn-primary">
        Aceptar
      </button>
    </div>
  </div>
</div>
