
export const environment = {
  production: true,
  version: 'v.q.0.3.0.8',
  urlEquipos:'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/api/v1/equipo/cataloge',
  urlCronEquipos: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/crun/run',
  login: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/',
  apikeyLogin: '8U9ezbHvfw5G4FX0ZnXvnRE0z5KKN5C5XaEOCna5',
  apikey: '8U9ezbHvfw5G4FX0ZnXvnRE0z5KKN5C5XaEOCna5',
  urlService: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/administradorOfertas',
  keyRecapcha: ' ',
  nameurlLogin: 'loginMotorAdminPre',
  urlInventarioDat: 'https://d1hoy0pjct6wwo.cloudfront.net/web/add-dat',
urlInventarioDatGet: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/api/v1/dat/list',
  urlGenerarl: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/api/v1/',
  urlInventarioDatDelete: 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa/api/v1/dat/onedelete',
  tokenInvenatarioDat: '4e6f6b3d8a4c',
  oringeCatalogo:'f4c5fc98-8a84-47b4-b74d-95fd05fcc206',
  urlogin: {
    url: '',
    apikey: ''
  }
};

