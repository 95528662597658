import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, takeUntil, map } from 'rxjs';
import { tablePtionsPageInterface } from '../../models/table.mode';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngUnsubscribe = new Subject<void>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() length: number | undefined = 0;
  @Input() pageSize = 10;
  @Input() conmutable = false;
  @Input() showPaginator = true;
  @Input() showCheckbox = false;
  pageSizeOptions: number[] = [1, 5, 10, 25, 100];
  public _dataSource: any = new MatTableDataSource([]);
  public displayedColumns: string[] = [];
  @Input() columns: any[] = [];
  @Input() set dataSource(data: any[]) {
    this.setDataSource(data);
  }
  @Output() Accions = new EventEmitter<any>();
  @Output() AccionsInput = new EventEmitter<any>();
  @Output() optionsTable = new EventEmitter<tablePtionsPageInterface>();
  @Input() serverless = true;
  selection = new SelectionModel<any>(true, []);
  checkedT = false;
  conteCecheed:any[] = []
  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
  ) {

  }
  ngOnInit(): void {
    this.displayedColumns = this.columns.map((tableColumn: any) => tableColumn.caption);
  }

  public setDataSource(data: any): void {
    this._dataSource = new MatTableDataSource<any>(data);
    this.changeDetectorRefs.detectChanges();
    if (!this.serverless) {
      this._dataSource.paginator = this.paginator;
    }
  }

  public action(key: string, element: any, i: number): void {
    const data = { ...element };
    delete data.accions;
    this.Accions.emit({
      key,
      data,
      index: i
    });
  }


  public actiontext(key: string, element: any, value: any): void {
    const data = { ...element };
    delete data.accions;
    this.Accions.emit({
      key,
      data,
      value
    });
  }


  public handlePageEvent(event): void {
    if (this.serverless) {
      this.optionsTable.emit(event);
    }

  }

  public checkeded( value: any, field: any | number): void {

    this.AccionsInput.emit({field:field,checked:value.target.checked});
  }

  public senddata(key:any, elementt: any, value: any, field: string | number, index: any): void {

    elementt[field] = value;
    const data = { ...elementt };
    delete data.accions;
    this.AccionsInput.emit({
      key: field,
      data,
      value,
      index
    });
  }

  ngAfterViewInit() {


  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this._dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
