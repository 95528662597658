import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { headEquiposList } from '@app/core/data/data';

@Component({
  selector: 'motor-calculo-list-equipos-model',
  templateUrl: './list-equipos-model.component.html',

  styleUrls: ['./list-equipos-model.component.scss']
})
export class ListEquiposModelComponent {

  header = headEquiposList
   body: any[] = [];
   totalRegistros = 0;
   textAlert = ' A continuación te mostramos los equipos encontrados, selecciona el que deseas agregar a la promoción';
   conteCecheed:any[] = [];
   constructor(
    public dialogRef: MatDialogRef<ListEquiposModelComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any = []
   ) {
     console.log(data);
     if (this.data?.equipos) {
       this.body = this.data?.equipos;
       this.totalRegistros = this.body.length;
       this.textAlert = this.textAlert + ', el total de registros es: ' + this.totalRegistros;
     } else {
       this.body = this.data;
     }


   }
   valueData({field, checked}){
    console.log(field, checked);
    if(checked){
      this.conteCecheed.findIndex((element) => element === field) === -1 ? this.conteCecheed.push(field) : null;
    }else{
      let index = this.conteCecheed.findIndex((element) => element.id !== field.id);
      console.log(index);
      if(index < 0){
        this.conteCecheed.splice(index, 1);
      }
    }
    console.log(this.conteCecheed);
   }

   close() {
     this.dialogRef.close(this.conteCecheed);
   }
 }
