<div class="mr-users-table background-white" [ngClass]="{'mr-users-table-conmutable': conmutable}">

  <div class="mr-table-general mr-users-table__contentTable">
    <table mat-table [dataSource]="_dataSource" matSort >
      <ng-container *ngFor="let column   of columns" [matColumnDef]="column.caption">
        <ng-container>
          <th mat-header-cell *matHeaderCellDef>
            <div class="mr-box-table-th"> {{column.caption}} </div>

          </th>
        </ng-container>


        <td mat-cell *matCellDef="let element;let i = index">

          <div *ngIf="column.type === 'check'"
            class="mr-box-table-td mr-users-table__contentTable__status mr-users-table__contentTable__input">
            <div class="mr-users-table__contentTable__input">

              <input type="checkbox"  (click)="checkeded($event,element)"  class="example-margin">
            </div>
          </div>



          <div *ngIf="column.type === 'object' "
            class="mr-box-table-td mr-users-table__contentTable__status mr-users-table__contentTable__status--object">
            <div class="mr-users-table__contentTable__status--object__item">
              <span *ngFor="let item of element[column.field]">{{item}}</span>
            </div>
          </div>
          <div *ngIf="column.type === 'array' "
            class="mr-box-table-td mr-users-table__contentTable__status mr-users-table__contentTable__status--array">
            <div class="mr-users-table__contentTable__status--array__item">
              <span *ngFor="let item of element[column.field]">
                {{item}}
              </span>
            </div>
          </div>

          <div *ngIf="column.type === 'status' " class="mr-box-table-td mr-users-table__contentTable__status">

            <span [ngClass]="{'mr-users-table__contentTable__status--false':element[column.field]===0}">
              {{(element[column.field]===0)?'Desactivada':'Activada'}}</span>
          </div>
          <div *ngIf="column.type === 'typeUser' " class="mr-box-table-td mr-users-table__contentTable__status">

            <span [ngClass]="{'mr-users-table__contentTable__status--false':element[column.field]===1}">
              {{(element[column.field]===1)?'Administrador':element[column.field]===0?'Usuario':'Admin listas
              blancas'}}</span>
          </div>

          <div *ngIf="column.type === 'status_bullet' "
            class="mr-box-table-td mr-users-table__contentTable__status-bullet">
            <div
              [ngClass]=" element[column.field][0]['value']?'mr-users-table__contentTable__status-bullet--active':'mr-users-table__contentTable__status-bullet--disable'">
            </div> <span class="mr-inter-text-min"> {{element[column.field][0]['text']}}</span>
          </div>

          <div *ngIf="column.type === 'text' " class="mr-box-table-td mr-box-table-td__text">{{element[column.field] }}
          </div>
          <div *ngIf="column.type === 'currency' " class="mr-box-table-td mr-box-table-td__text">{{element[column.field]
            | currency }}</div>
          <div *ngIf="column.type === 'percent' " class="mr-box-table-td mr-box-table-td__text">{{element[column.field]
            | percent }}</div>
          <div *ngIf="column.type === 'link' " class="mr-box-table-td mr-decoration-curosr-pinter ">
            <a routerLink="{{element.accions.url}}"> {{element.accions.text }}</a>
          </div>
          <div *ngIf="column.type ==='button' " class="mr-box-table-td ">

            <section *ngFor="let button of element.accions">
              <button *ngIf="button.text" matTooltip="{{button.text}}" type="button"
                (click)="action(button.key,element,i)" mat-icon-button>
                <mat-icon svgIcon="{{button.icon}}"></mat-icon>
              </button>
              <button *ngIf="!button.text" type="button" (click)="action(button.key,element,i)" mat-icon-button>
                <mat-icon svgIcon="{{button.icon}}"></mat-icon>
              </button>
            </section>
          </div>
          <div *ngIf="column.type ==='buttontext' " class="mr-box-table-td ">
            <section *ngFor="let button of element.accions">
              <span class="mr-decoration-curosr-pinter" (click)="actiontext(button.key,element,button.value)">
                {{button.text }}
              </span>
            </section>

          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator *ngIf="showPaginator" color="primary" [length]="length" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" (page)="handlePageEvent($event)">
    </mat-paginator>
  </div>

</div>
