import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, firstValueFrom } from 'rxjs';
import { formOfertaJson } from '../models/ofetas.model';
import { payloadGeneric } from '../models/generig.mode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  IsSpinner$ = new Subject<boolean>();
  IsLogin$ = new Subject<boolean>();
  IsCatalog$ = new Subject<boolean>();
  url = environment.urlService;
  urlInventarioDrupal = environment.urlInventarioDat;
  urlInventarioDatGet = environment.urlInventarioDatGet
  urlDeleteDat = environment.urlInventarioDatDelete;
  urlGenerarl= environment.urlGenerarl;
  filtros: payloadGeneric;
  constructor(
    private http: HttpClient,
  ) { }

  getFormoferta() {
    return this.http.get<formOfertaJson>('../assets/json/form/form-oferta.json');
  }

  generalppost(body) {
    return firstValueFrom(this.http.post<any>(this.url, body));
  }
  generalurl(url, body, size) {
    const header = new HttpHeaders({ "Content-Length": size, "Content-Type": "text/csv" });
    return firstValueFrom(this.http.put<any>(url, body, { headers: header }));
  }

  cargarInventarioDat(filtros) {
    return firstValueFrom(this.http.post<any>(environment.urlGenerarl+'dat/savemultiple', filtros));
  }
  getInventarioDat(body) {
    return firstValueFrom(this.http.post<any>(this.urlInventarioDatGet,body));
  }

  getInventarioEquipos(canal,region,dat){
    return firstValueFrom(this.http.get<any>(environment.urlEquipos+'?canal='+canal+'&region='+region+'&dat=all'));
  }

  deleteInventarioDat(body){
    return firstValueFrom(this.http.post<any>(environment.urlInventarioDatDelete,body));
  }

  runcron(){
    return firstValueFrom(this.http.get<any>(environment.urlCronEquipos));
  }

}
