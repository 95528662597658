import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  xapikey = environment.apikey;
  constructor() { }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request = req;
    if ((request.url === 'https://7hrbcnlook.execute-api.us-east-1.amazonaws.com/pre/loginMotorAdminPre') || (request.url === 'https://wih39cmyk8.execute-api.us-east-1.amazonaws.com/qa')) {
      request = req.clone({
        setHeaders: {
          'x-api-key': environment.apikeyLogin
        }
      });
    }  else if (request.url.includes('https://dedm8q2o4wuuo.cloudfront.net')) {
      request = req.clone({
        setHeaders: {

        }
      });
    } else if (request.url.includes('https://pre-telcel.s3.amazonaws.com/sepomex/')) {
      request = req.clone();

    }else if (request.url.includes('/equipo/cataloge') ||request.url.includes('/dat/list')||request.url.includes('/dat/onedelete')||request.url.includes('/dat/savemultiple')) {
      request = req.clone({
        setHeaders: {
          'x-api-key': environment.apikey,
          'origen': environment.oringeCatalogo
        }
      });

    }else {
      request = req.clone({
        setHeaders: {
          'x-api-key': this.xapikey
        }
      });
    }



    return next.handle(request);
  }
}
