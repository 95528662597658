
export const DEFAULT_LANGUAGE = 'es';

export const DEFAULT_PAGE_SIZE_TABLE = 10;

export const DEFAULT_PAGE_INIT_TABLE = 1;

export const DEFAULT_PAGE_INIT_TABLE_FILTER = 0;

export const DEFAULT_OPTIONS_PAGES_TABLE = [1, 5, 10, 25, 100];
export const rfcPattern = /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;



export const headErrorsList = [
  { caption: 'Linea', field: 'linea', type: 'text' },
  { caption: 'Agente', field: 'text', type: 'text' },
  { caption: 'Descripción', field: 'error', type: 'text' },
];
export const headEquiposList = [
  { caption: 'Select', field: 'check', type: 'check' },
  { caption: 'Sku', field: 'sku', type: 'text' },
  { caption: 'Marca', field: 'marca', type: 'text' },
  { caption: 'Modelo', field: 'modelo', type: 'text' },
  { caption: 'Color', field: 'color', type: 'text' },
  { caption: 'Region', field: 'region', type: 'text' },
  { caption: 'Canal', field: 'canal', type: 'text' },
  { caption: 'Gamma', field: 'gamma', type: 'text' }
];

export const headErrorSku = [
  { caption: 'SKU', field: 'sku', type: 'text' },
  { caption: 'Descripción', field: 'error', type: 'text' },
];

export const headDatalistOferta = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Nombre', field: 'nombre', type: 'text' },
  { caption: 'Descripcion', field: 'descripcion', type: 'text' },
  { caption: 'F. Incio', field: 'fecha_inicio', type: 'text' },
  { caption: 'F. Fin', field: 'fecha_fin', type: 'text' },
  { caption: 'Estatus', field: 'estatus', type: 'status' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDatalistPromocion = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Nombre', field: 'nombre', type: 'text' },
  { caption: 'Descripcion', field: 'descripcion', type: 'text' },
  { caption: 'F. Incio', field: 'fecha_inicio', type: 'text' },
  { caption: 'F. Fin', field: 'fecha_fin', type: 'text' },
  { caption: 'Estatus', field: 'estatus', type: 'status' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDatalistRegion = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Nombre', field: 'region', type: 'text' },
  { caption: 'Descripcion', field: 'descripcion', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDatalistNivelRiesgo = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Limite de credito', field: 'limiteCredito', type: 'currency' },
  { caption: 'Enganche', field: 'enganche', type: 'percent' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDatalistCanal = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Canal', field: 'canal', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataCondicionesPago = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Clave', field: 'clave', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Sobreprecio', field: 'sobreprecio', type: 'percent' },
  { caption: 'Meses', field: 'meses', type: 'text' },
  { caption: 'Parcialidades', field: 'parcialidades', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataInventarioDat = [
  { caption: 'SKU', field: 'sku', type: 'text' },
  { caption: 'Nombre Comercial', field: 'nombre_comercial', type: 'text' },
  { caption: 'Color', field: 'color', type: 'text' },
  { caption: 'AppEnrolamiento', field: 'gamma', type: 'text' },
  { caption: 'Región', field: 'region', type: 'text' },
  { caption: 'Precio', field: 'precio', type: 'currency' },
  { caption: 'Acciones', field: 'accions', type: 'button' },

];

export const headDataTipoCliente = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Tipo cliente', field: 'tipo_cliente', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataUsuario = [
  { caption: 'Num', field: 'number', type: 'text' },
  { caption: 'Nombre', field: 'nombre', type: 'text' },
  { caption: 'Usuario', field: 'user', type: 'text' },
  { caption: 'Tipo usuario', field: 'admin', type: 'typeUser' },
  { caption: 'Estatus', field: 'status', type: 'status' },
  { caption: 'Ultima modificacion', field: 'lastupdate', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataSepeomex = [
  { caption: 'ID', field: 'id_cat_sepomex', type: 'text' },
  { caption: 'Codigo Postal', field: 'codigo_postal', type: 'text' },
  { caption: 'Colonia', field: 'colonia', type: 'text' },
  { caption: 'Ciudad', field: 'ciudad', type: 'text' },
  { caption: 'Municipio', field: 'municipio', type: 'text' },
  { caption: 'Estado', field: 'estado', type: 'text' }
];


export const headDataequipos = [
  { caption: 'SKU', field: 'sku', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Color', field: 'color', type: 'text' },
  { caption: 'Marca', field: 'marca', type: 'text' },
  { caption: 'Modelo', field: 'modelo_comercial', type: 'text' },
  { caption: 'Region', field: 'region', type: 'text' },
  { caption: 'Precio sin Iva', field: 'precioiva', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const regiones = [
  {
    label: 'Región 1'
  },
  {
    label: 'Región 2'
  },
  {
    label: 'Región 3'
  },
  {
    label: 'Región 4'
  },
  {
    label: 'Región 5'
  },
  {
    label: 'Región 6'
  }

]

export const nivelRiesgo = [
  {
    id: 1,
    label: 'Nivel 1'
  },
  {
    id: 2,
    label: 'Nivel 2'
  },
  {
    id: 3,
    label: 'Nivel 3'
  },
  {
    id: 4,
    label: 'Nivel 4'
  },
  {
    id: 5,
    label: 'Nivel 5'
  },
  {
    id: 6,
    label: 'Nivel 6'
  }
]

export const canal = [
  {
    id: 1,
    descripcion: 'Distribuidor',
    canal: 'DAT'
  },
  {
    id: 2,
    descripcion: 'Centro de Atención a Clientes',
    canal: 'CAC'
  }
]

export const tipoCliente = [
  {
    id: 1,
    descripcion: 'Masivo',
    canal: 'M'
  },
  {
    id: 2,
    descripcion: 'Centro de Atención a Clientes',
    canal: 'C'
  },
  {
    id: 3,
    descripcion: 'Otro',
    canal: 'o'
  }
]

export const estados = [
  {
    id: 1,
    descripcion: 'Ciudad de México',

  },
  {
    id: 2,
    descripcion: 'Aguascalientes',
    canal: 'C'
  },
  {
    id: 3,
    descripcion: 'Baja California',
    canal: 'o'
  },
  {
    id: 4,
    descripcion: 'Baja California',
    canal: 'o'
  },
  {
    id: 5,
    descripcion: 'Puebla',
    canal: 'o'
  }
]

export const ciudad = [
  {
    id: 1,
    descripcion: 'Ciudad de México',

  },
  {
    id: 2,
    descripcion: 'Sabancuy',
    canal: 'C'
  },
  {
    id: 3,
    descripcion: 'Saltillo',
    canal: 'o'
  },
  {
    id: 4,
    descripcion: 'Monclova',
    canal: 'o'
  },
  {
    id: 5,
    descripcion: 'Ciudad de Villa de Álvarez',
    canal: 'o'
  }
]


export const condicionesPago = [
  {
    "id": 1,
    "clave": "F014",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.6,
    "plazos": "S",
    "meses": 6,
    "nivelriesgo": "[3,4]",
    "parcialidades": 26
  },
  {
    "id": 2,
    "clave": "F018",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.85,
    "plazos": "S",
    "meses": 6,
    "nivelriesgo": "[5,6]",
    "parcialidades": 26
  },
  {
    "id": 3,
    "clave": "F027",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.3,
    "plazos": "S",
    "meses": 6,
    "nivelriesgo": "[1,2]",
    "parcialidades": 26
  },
  {
    "id": 4,
    "clave": "F038",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.9,
    "plazos": "S",
    "meses": 9,
    "nivelriesgo": "[5,6]",
    "parcialidades": 39
  },
  {
    "id": 5,
    "clave": "F041",
    "descripcion": "AMIGO PAGUITOS 6 MESES Q",
    "sobreprecio": 0.3,
    "plazos": "Q",
    "meses": 6,
    "nivelriesgo": "[1,2]",
    "parcialidades": 13
  },
  {
    "id": 6,
    "clave": "F043",
    "descripcion": "AMIGO PAGUITOS 6 MESES Q",
    "sobreprecio": 0.6,
    "plazos": "Q",
    "meses": 6,
    "nivelriesgo": "[3]",
    "parcialidades": 13
  },
  {
    "id": 7,
    "clave": "F047",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.3,
    "plazos": "M",
    "meses": 6,
    "nivelriesgo": "[1,2]",
    "parcialidades": 6
  },
  {
    "id": 8,
    "clave": "F059",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.35,
    "plazos": "S",
    "meses": 9,
    "nivelriesgo": "[1,2]",
    "parcialidades": 39
  },
  {
    "id": 9,
    "clave": "F060",
    "descripcion": "AMIGO PAGUITOS 9 MESES Q",
    "sobreprecio": 0.35,
    "plazos": "Q",
    "meses": 9,
    "nivelriesgo": "[1,2]",
    "parcialidades": 18
  },
  {
    "id": 10,
    "clave": "F061",
    "descripcion": "AMIGO PAGUITOS 9 MESES Q",
    "sobreprecio": 0.35,
    "plazos": "M",
    "meses": 9,
    "nivelriesgo": "[1,2]",
    "parcialidades": 9
  },
  {
    "id": 11,
    "clave": "F062",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.65,
    "plazos": "S",
    "meses": 9,
    "nivelriesgo": "[3,4]",
    "parcialidades": 39
  },
  {
    "id": 12,
    "clave": "F063",
    "descripcion": "AMIGO PAGUITOS 9 MESES Q",
    "sobreprecio": 0.65,
    "plazos": "Q",
    "meses": 9,
    "nivelriesgo": "[3]",
    "parcialidades": 18
  },
  {
    "id": 13,
    "clave": "F067",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.4,
    "plazos": "S",
    "meses": 12,
    "nivelriesgo": "[1,2]",
    "parcialidades": 52
  },
  {
    "id": 14,
    "clave": "F068",
    "descripcion": "AMIGO PAGUITOS 12 MESES Q",
    "sobreprecio": 0.4,
    "plazos": "Q",
    "meses": 12,
    "nivelriesgo": "[1,2]",
    "parcialidades": 26
  },
  {
    "id": 15,
    "clave": "F069",
    "descripcion": "AMIGO PAGUITOS 12 MESES M",
    "sobreprecio": 0.4,
    "plazos": "M",
    "meses": 12,
    "nivelriesgo": "[1,2]",
    "parcialidades": 12
  },
  {
    "id": 16,
    "clave": "F070",
    "descripcion": "AMIGO PAGUITOS 6 MESES S",
    "sobreprecio": 0.7,
    "plazos": "S",
    "meses": 12,
    "nivelriesgo": "[3]",
    "parcialidades": 52
  },
  {
    "id": 17,
    "clave": "F071",
    "descripcion": "AMIGO PAGUITOS 12 MESES Q",
    "sobreprecio": 0.7,
    "plazos": "Q",
    "meses": 12,
    "nivelriesgo": "[3]",
    "parcialidades": 26
  }
]


export const campanasHead = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Nombre', field: 'nombre', type: 'text' },
  { caption: 'Descripción', field: 'descripcion', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];


export const campanasUsuariosHead = [
  { caption: 'ID', field: 'id', type: 'text' },
  { caption: 'Nombre', field: 'nombre', type: 'text' },
  { caption: 'Ap. Paterno', field: 'ap_paterno', type: 'text' },
  { caption: 'Ap. Materno', field: 'ap_materno', type: 'text' },
  { caption: 'R.F.C.', field: 'rfc', type: 'text' },
  { caption: 'Cargo', field: 'cargo', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDatafzaVentas = [
  { caption: 'ID', field: 'id_fza_venta', type: 'text' },
  { caption: 'Nombre', field: 'fza_vta', type: 'text' },
  { caption: 'ID fuerza', field: 'id_fza', type: 'text' },
  { caption: 'Clave Sap', field: 'clave_sap', type: 'text' },
  { caption: 'ID DAT', field: 'id_dat', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataSubfzaVentas = [
  { caption: 'ID', field: 'id_subvta', type: 'text' },
  { caption: 'ID Distribuidor', field: 'id_distribuidor', type: 'text' },
  { caption: 'Nombre', field: 'subvta', type: 'text' },
  { caption: 'Ciudad', field: 'ciudad', type: 'text' },
  { caption: 'Estado', field: 'estado', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];

export const headDataAlmacenCAT = [
  { caption: 'Almacen', field: 'almacen', type: 'text' },
  { caption: 'Oficina Almacen', field: 'oficinaAlmacen', type: 'text' },
  { caption: 'Canal', field: 'canal', type: 'text' },
  { caption: 'Fuerza de ventas', field: 'fzaVenta', type: 'text' },
  { caption: 'Nombre Cac', field: 'nombreCac', type: 'text' },
  { caption: 'Oficina', field: 'oficina', type: 'text' },
  { caption: 'Región', field: 'region', type: 'text' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
];


export const headUserDat = [
  { caption: 'ID Agente', field: 'idAgente', type: 'text' },
  { caption: 'Perfil', field: 'perfil', type: 'text' },
  { caption: 'Nombre', field: 'nombreAgente', type: 'text' },
  { caption: 'Fuerza venta', field: 'fzaVenta', type: 'text' },
  { caption: 'Canal', field: 'canal', type: 'text' },
  { caption: 'Región', field: 'idRegion', type: 'text' },
  { caption: 'Estatus', field: 'estatusA', type: 'status' },
  { caption: 'Acciones', field: 'accions', type: 'button' },
]
